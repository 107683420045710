<template>
    <div class="app-body">
        <bread-crumb></bread-crumb>
        <el-card class="box-card customerDetail">
            <div slot="header" class="clearfix">
                <div class="a-flex-rfsc">
                    <span class="a-fw-700">{{ companyInfo.company.brand }}</span>

                    <el-tag class="a-ml-08" type="danger" v-if="companyInfo.company.regState == 3">{{
                            companyInfo.company.regStateText
                    }}</el-tag>
                    <el-tag class="a-ml-08" v-else-if="companyInfo.company.regState < 3">{{
                            companyInfo.company.regStateText
                    }}</el-tag>
                </div>
            </div>
            <div class="customerDetail-content">
                <div class="itembox" v-if="companyInfo.company.regState == 3">
                    <span class="title">审核结果</span>
                    <div class="content">
                        <div>
                            <span>审核结果</span>
                            <span class="a-c-red">拒绝</span>
                        </div>
                        <div class="a-d-flex">
                            <span>拒绝原因</span>
                            <span class="a-flex-1">{{ companyInfo.company.regFeedback }}</span>
                        </div>
                    </div>

                </div>
                <div class="itembox">
                    <span class="title">管理员实名</span>
                    <div class="content">
                        <div>
                            <span>管理员姓名</span>
                            <span>{{ companyInfo.user.realName }}</span>
                        </div>
                        <div>
                            <span>身份证号</span>
                            <span>{{ companyInfo.user.idCardNumber }}</span>
                        </div>
                        <div class="a-flex-rfsc">
                            <span>身份证照片</span>
                            <LeBtnPreviewImg :imgList="imgList"></LeBtnPreviewImg>
                        </div>
                    </div>

                </div>
                <div class="itembox">
                    <span class="title">商户信息</span>
                    <div class="content">
                        <div>
                            <span>主体类型</span>
                            <span>{{ type[companyInfo.company.newType] }}</span>
                        </div>
                        <div>
                            <span>商户简称</span>
                            <span>{{ companyInfo.company.brand }}</span>
                        </div>
                        <div>
                            <span>商户全称</span>
                            <span>{{ companyInfo.company.name }}</span>
                        </div>
                        <div>
                            <span>所在地区</span>
                            <span>{{ companyInfo.company.province }}{{ companyInfo.company.city }}{{
                                    companyInfo.company.district
                            }}</span>
                        </div>
                        <div>
                            <span>统一社会信用代码</span>
                            <span>{{ companyInfo.company.certNumber || '无' }}</span>
                        </div>
                        <div class="a-flex-rfsc">
                            <span>营业执照</span>
                            <LeBtnPreviewImg :imgList="[companyInfo.company.certImagePath]"
                                v-if="companyInfo.company.certImagePath"></LeBtnPreviewImg>
                            <span v-else>无</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="a-line-t-e0 footerBox" v-if="companyInfo.company.regState < 3 && this.company.id == 2">
                <el-button type="primary" class="a-ml-24 a-mt-15 myblue" @click="authCompany()">
                    &nbsp;&nbsp;&nbsp;通过&nbsp;&nbsp;&nbsp;</el-button>
                <el-button class="a-ml-24 a-mt-15" style="margin-left: 24px" type="danger"
                    @click="examineDialog.dialogFormVisible = true">
                    &nbsp;&nbsp;&nbsp;拒绝&nbsp;&nbsp;&nbsp;
                </el-button>

            </div>
        </el-card>
        <le-examine-dialog :dialogObj.sync="examineDialog" @objection="objection"></le-examine-dialog>

    </div>
</template>
<script>
import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex';
import LeBtnPreviewImg from '../components/poppup/le-btn-preview-img.vue';
import LeExamineDialog from '../components/poppup/le-examine-dialog.vue';
export default {
    inject: ["reload"],
    components: { LeBtnPreviewImg, LeExamineDialog },
    data() {
        return {
            imgList: [],
            dialogFormVisible: false,
            examineDialog: {
                title: '审核拒绝',
                dialogFormVisible: false,
                objection: "",
                placeholder: '请输入拒绝原因...',
                cancle: "取消",
                sure: '确定'
            },
            companyInfo: { company: {}, user: {} },
            type: ['自然人', '企业', '个体工商户', '个体工商户', '政府及事业单位', '其他组织']

        }
    },
    computed: {
        ...mapState({
            company: state => state.company.company
        })
    },
    mounted() {

        this.getCompanyInfo()
    },
    methods: {
        getCompanyInfo() {
            this.$Axios._get({
                url: this.$Config.apiUrl.getCompanyManagerInfo,
                params: {
                    companyId: this.$route.query.companyId
                }
            }).then((res) => {
                if (res.result.code == 0) {
                    let data = res.result.data
                    this.companyInfo = data
                    if (data.user.idCardImagePath && data.user.idCardImagePath) {
                        this.imgList = [data.user.idCardImagePath, data.user.idCardBackImagePath]
                    }
                } else {
                    this.$message.error(res.result.msg)
                }
            }).catch((error) => {
                this.$message.error(error.result.msg)
            })
        },
        objection(val) {
            console.log('拒绝', val);
            this.authCompany(val)
        },
        //商户入驻审核
        authCompany(val) {
            console.log(val)
            this.$Axios._post({
                url: this.$Config.apiUrl.authCompany,
                params: {
                    authResult: val ? false : true,
                    authMsg: val ? val : '',
                    companyId: parseInt(this.$route.query.companyId)
                }
            }).then((res) => {
                if (res.result.code == 0) {
                    this.$notify({
                        title: '审核成功',
                        type: 'success'
                    })
                    if (val) {
                        this.reload();
                    } else {
                        this.$router.push({
                            path: '/customer/customer-list'
                        })
                    }

                } else {
                    this.$message.error(res.result.msg)
                }
            }).catch((error) => {
                this.$message.error(error.result.msg)
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.customerDetail {
    height: 100%;
    position: relative;

    &-content {}


}

.itembox {
    padding: 16px 24px 40px;

    .title {
        font-size: 16px;
        line-height: 24px;
        font-weight: bold;
    }

    .content {
        width: 100%;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;

        >div {




            padding-top: 16px;
            width: 33.3%;
            font-size: 14px;
            line-height: 22px;

            >* {
                &:nth-of-type(1) {
                    color: #606366;
                    margin-right: 16px;
                }

                &:nth-of-type(2) {
                    // color: #303133;
                }
            }
        }
    }


}

// .footerBox {
//     left: 0;
//     right: 0;
//     position: absolute;
//     bottom: 0;
//     background: #fff;
//     padding-bottom: 24px;
// }
</style>